export function usePagination(initialPage = 1, initialLimit = 36) {
  const { query } = useUrlForm({
    page: { type: "number", default: initialPage },
    limit: { type: "number", default: initialLimit },
  });

  const page = computed({
    get() {
      return query.page;
    },
    set(val: number) {
      query.page = val;
    },
  });

  const limit = computed({
    get() {
      return query.limit;
    },
    set(val: number) {
      query.limit = val;
    },
  });

  const offset = computed(() => (page.value - 1) * query.limit);

  return {
    page,
    limit,
    offset,
  };
}

export function useInternalPagination(initialPage = 1, initialLimit = 36) {
  const page = ref(initialPage);
  const limit = ref(initialLimit);

  const offset = computed(() => (page.value - 1) * limit.value);

  return {
    page,
    limit,
    offset,
  };
}
