import deepEqual from "deep-eql";
import type {
  QueryTemplate,
  InferTypeFromComplexString,
  ParsedQueryFromTemplate,
} from "./route";

type UrlFormOptions = {
  updateUrlOnChange?: boolean;
};

type UrlFormResult<
  Q extends QueryTemplate,
  T extends ParsedQueryFromTemplate<Q>,
> = {
  query: T;
  isInDefaultState: ComputedRef<boolean>;
  resetToDefaults: () => void;
  updateUrl: () => void;
  onChange: (hook: () => any) => void;
};

function filterQueryContent(
  query: Record<string, any>,
  template: QueryTemplate,
) {
  const finalQuery: Record<string, any> = {};

  for (const key in query) {
    const value = query[key];

    if (
      !value ||
      value === template[key]?.default ||
      (Array.isArray(value) && value.length === 0)
    ) {
      continue;
    }

    finalQuery[key] = value;
  }

  return finalQuery;
}

export function useUrlForm<
  Q extends QueryTemplate = QueryTemplate,
  T extends ParsedQueryFromTemplate<Q> = ParsedQueryFromTemplate<Q>,
>(template: Q, options?: UrlFormOptions): UrlFormResult<Q, T> {
  const route = useRoute();

  const updateUrlOnChange = options?.updateUrlOnChange ?? true;

  const computedQuery = useRouteQueryTemplate(template);
  const query = reactive(
    Object.fromEntries(
      Object.keys(computedQuery).map((key) => [key, computedQuery[key].value]),
    ),
  );
  const onChangeHooks: (() => any)[] = [];

  watch(computedQuery, (newComputedQuery, oldComputedQuery) => {
    if (deepEqual(oldComputedQuery, query)) {
      return;
    }

    Object.keys(query).forEach((name) => {
      if (!(name in query)) {
        return;
      }

      query[name] = unref(newComputedQuery[name]);
    });

    onChangeHooks.forEach((hook) => hook());
  });

  const isInDefaultState = computed(() =>
    Object.typedEntries(query).every(([name, value]) =>
      deepEqual(value, template[name].default),
    ),
  );

  function resetToDefaults() {
    Object.keys(query).forEach((name) => {
      query[name] = template[name].default;
    });
  }

  const updateUrl = () => {
    navigateTo({
      query: filterQueryContent(
        {
          ...route.query,
          ...query,
        },
        template,
      ),
    });
  };

  watch(query, () => {
    if (updateUrlOnChange) {
      updateUrl();
    }
  });

  return {
    query: query as T,
    isInDefaultState,
    resetToDefaults,
    updateUrl,
    onChange: (hook: () => any) => onChangeHooks.push(hook),
  };
}
